/**
 * Navigates to a URL
 * @param {*} url - url to navigate to
 * @param {*} external - if true, open a new page or tab
 */
export function visitUrl(url, external = false) {
  if (external) {
    // Simulate `target="_blank" rel="noopener noreferrer"`
    // See https://mathiasbynens.github.io/rel-noopener/
    const otherWindow = window.open();
    otherWindow.opener = null;
    otherWindow.location.assign(url);
  } else {
    window.location.assign(url);
  }
}

/**
 * Returns current base URL
 */
export function getBaseURL() {
  const { protocol, host } = window.location;
  return `${protocol}//${host}`;
}

/**
 * Returns true if url is an absolute URL
 *
 * @param {String} url
 */
export function isAbsolute(url) {
  return /^https?:\/\//.test(url);
}

/**
 * Converts a relative path to an absolute or a root relative path depending
 * on what is passed as a basePath.
 *
 * @param {String} path       Relative path, eg. ../img/img.png
 * @param {String} basePath   Absolute or root relative path, eg. /user/project or
 *                            https://gitlab.com/user/project
 */
export function relativePathToAbsolute(path, basePath) {
  const absolute = isAbsolute(basePath);
  const base = absolute ? basePath : `file:///${basePath}`;
  const url = new URL(path, base);
  url.pathname = url.pathname.replace(/\/\/+/g, '/');
  return absolute ? url.href : decodeURIComponent(url.pathname);
}

/**
 * Returns a normalized url
 *
 * https://gitlab.com/foo/../baz => https://gitlab.com/baz
 *
 * @param {String} url - URL to be transformed
 * @param {String?} baseUrl - current base URL
 * @returns {String}
 */
export const getNormalizedURL = (url, baseUrl) => {
  const base = baseUrl || getBaseURL();
  try {
    return new URL(url, base).href;
  } catch (_) {
    return '';
  }
};

/**
 * Return an object as serialized params for a GET request
 *
 * @param {Object} params
 * @returns {String}
 */
export const parseQueryParams = (params) => {
  return Object.keys(params)
    .map((key) => {
      // if a param is an array, add its elements individually to the query string
      // example: properties: ['viewCount', 'commentCount'] becomes 'properties%5B%5D=viewCount&properties%5B%5D=commentCount'
      const param = params[key];
      if (Array.isArray(param)) {
        return param.map((val) => `${encodeURI(`${key}[]`)}=${val}`).join('&');
      }
      return `${key}=${param}`;
    })
    .join('&');
};

/**
 * Replace the :id portion of a /tenants/:id URL with a new id
 * @param {String, Number} id
 * @returns {String}
 */
export const replaceTenantIdInURL = (id, pathname) => {
  const parts = pathname.split('/');
  if (parts.length < 2 || parts[1] !== 'tenants') {
    throw new Error('replaceTenantIdInURL can only be used in tenant routes');
  }

  parts[2] = id.toString();
  return parts.join('/');
};

export const isKeyboardNavigationCapable = (pathname) => {
  if (!pathname.startsWith('/tenants')) return false;
  const disabledPages = ['config_change_logs', 'audit_logs', 'amp_jobs', 'hosted_runners'];

  return disabledPages.every((page) => !pathname.includes(page));
};
