import { useQuery, useQueryClient } from '@tanstack/vue-query';
import { unref, computed } from 'vue';
import { getTenant, getTenantEdit } from '~/api/tenants';

/**
 * @param {*} param0
 * @param {Ref<Number|String>|Number|String} tenantId
 * @param {Ref<Boolean>|Boolean} enabled Disable or enable the query manually
 * @param {Ref<Boolean>|Boolean} [edit=false] Should use the edit tenant API?
 * @returns
 */
export const useTenantQuery = ({ tenantId, enabled: userEnabled = true, edit = false } = {}) => {
  const queryKey = ['tenant', tenantId, edit];

  const enabled = computed(() => {
    return Boolean(unref(tenantId)) && unref(userEnabled);
  });

  const client = useQueryClient();

  const tenantQueryFn = computed(() => (unref(edit) ? getTenantEdit : getTenant));

  const query = useQuery({
    queryFn: () => tenantQueryFn.value(unref(tenantId)).then((res) => res.data.data),
    queryKey,
    enabled,
    staleTime: 10000,
  });

  const { data, error, status, isLoading } = query;

  return {
    queryKey,
    data,
    error,
    status,
    isLoading,
    invalidate: () => client.invalidateQueries({ queryKey }),
  };
};
