import { useQuery, useMutation, useQueryClient } from '@tanstack/vue-query';
import { computed } from 'vue';
import { updateNotificationPreferences } from '~/api/notification_preferences';
import { getCurrentUser } from '~/api/current_user';
import { ROLE } from '~/lib/constants';

export const useUserQuery = () => {
  const client = useQueryClient();

  const queryKey = ['user', 'me'];
  const queryFn = () => getCurrentUser().then((res) => res.data.data);

  const query = useQuery({
    queryFn,
    queryKey,
    staleTime: 10000,
    retry: (failureCount, error) => {
      // 401 means the user is not logged in, do not retry
      if (error.response?.status === 401) return false;

      return failureCount < 3;
    },
  });

  const { data, error } = query;

  const updateNotificationPreferencesMutation = useMutation({
    mutationFn: (notification_preference) => updateNotificationPreferences(notification_preference),
    onMutate: async (notification_preference) => {
      await client.cancelQueries({ queryKey });

      const previous = client.getQueryData(queryKey);
      client.setQueryData(queryKey, (old) => ({
        ...old,
        notification_preference,
      }));

      return { previous };
    },
    onError: (e, _, context) => {
      client.setQueryData(queryKey, context.previous);
    },
    onSettled: () => {
      client.invalidateQueries({ queryKey });
    },
  });

  const { mutateAsync: updateNotificationPreferencesAsync } = updateNotificationPreferencesMutation;

  const isGitlabOperator = computed(() => data.value?.role === ROLE.GITLAB_OPERATOR);
  const isGitlabProvisioner = computed(() => data.value?.role === ROLE.GITLAB_PROVISIONER);
  const isGitlabUser = computed(() => data.value?.gitlab_user);
  const isTenantAdmin = computed(() => data.value?.role === ROLE.TENANT_ADMIN);

  const ensure = () => client.ensureQueryData({ queryKey, queryFn });

  return {
    queryKey,
    data,
    error,
    updateNotificationPreferencesAsync,
    isGitlabOperator,
    isGitlabUser,
    isTenantAdmin,
    isGitlabProvisioner,
    ensure,
  };
};
