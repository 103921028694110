<script setup>
import { GlLink, GlPopover, GlLoadingIcon, GlIcon } from '@gitlab/ui';
import { computed, onBeforeUnmount, ref } from 'vue';
import { useTenantQuery } from '~/queries/use_tenant_query';

defineOptions({
  compatConfig: {
    INSTANCE_ATTRS_CLASS_STYLE: false,
  },
});

const props = defineProps({
  href: {
    type: String,
    required: true,
    validator: (href) => href.startsWith('/tenants/'),
  },
});

const queryEnabled = ref(false);

let hoveringTimeout = null;
const onMouseover = () => {
  if (queryEnabled.value) return;

  hoveringTimeout = setTimeout(() => {
    queryEnabled.value = true;
  }, 150);
};

const onMouseout = () => {
  if (hoveringTimeout) clearTimeout(hoveringTimeout);
};

const findTenantId = (url) => {
  const regex = /\/tenants\/(\w+)(?:\/|$)/;
  const match = url.match(regex);
  return match ? match[1] : null;
};

const tenantId = computed(() => findTenantId(props.href));
const { data: tenant } = useTenantQuery({ tenantId, enabled: queryEnabled });

const tenantLinkRef = ref(null);

onBeforeUnmount(() => {
  clearTimeout(hoveringTimeout);
});
</script>

<template>
  <gl-link
    ref="tenantLinkRef"
    v-bind="$attrs"
    :href="href"
    data-testid="link"
    @mouseover="onMouseover"
    @mouseout="onMouseout"
    @focus="onMouseover"
    @blur="onMouseout"
  >
    <slot></slot>
  </gl-link>

  <gl-popover
    trigger="focus hover"
    :title="tenant?.name || 'Loading'"
    :show-close-button="false"
    placement="top"
    boundary="viewport"
    :target="tenantLinkRef"
  >
    <gl-loading-icon v-if="!tenant" small />

    <template v-else>
      <ul class="gl-list-none gl-pl-0" data-testid="data-list">
        <li><gl-icon name="tanuki" /> {{ tenant.gitlab_version }}</li>
        <li><gl-icon name="admin" /> {{ tenant.instrumentor_version }}</li>
      </ul>
    </template>
  </gl-popover>
</template>
