<script setup>
import { ref, computed, onBeforeUnmount } from 'vue';
import {
  GlLink,
  GlDisclosureDropdown,
  GlAlert,
  GlBroadcastMessage,
  GlIcon,
  GlButton,
} from '@gitlab/ui';
import { useUserQuery } from '~/queries/use_user_query';
import { usePCLQuery } from '~/queries/use_pcl_query';
import { useTenantsQuery } from '~/queries/use_tenants_query';
import { isKeyboardNavigationCapable, replaceTenantIdInURL } from '~/helpers/url_utilities';

import TanukiLogo from '~/layout/navbar/tanuki_logo.vue';
import ApiErrorAlert from '~/common/api_error_alert.vue';
import QuickTenantSearch from '~/common/quick_tenant_search.vue';

defineOptions({ inheritAttrs: false });

const props = defineProps({
  links: {
    type: Object,
    required: true,
  },
  switchboardVersion: {
    type: String,
    required: true,
  },
  tenant: {
    type: Object,
    default: null,
  },
});

const apiError = ref(null);

// TODO: Should be replaced by view router when its available in the navbar
const shouldShowSearchButton = window.location.pathname !== '/tenants';

const { data: user, isGitlabUser, updateNotificationPreferencesAsync } = useUserQuery();

const notificationPreferencesUpdated = ref(false);
const toggleUserNotificationPreferences = async () => {
  try {
    await updateNotificationPreferencesAsync(!user.value.notification_preference);

    notificationPreferencesUpdated.value = true;
  } catch (e) {
    apiError.value = e.response.data;
  }
};

const envLabel = computed(() => {
  if (!isGitlabUser.value || !user.value?.env) return '';

  return `(${user.value.env})`;
});

const userMenuItems = computed(() => {
  const topItems = {
    operations_schedule: 'Operations Schedule',
    manage_gitlab_users: 'Manage GitLab Users',
    tenants: 'Change Tenants',
    onboarding_tenants: 'Onboarding Tenants',
    access_tokens: 'Access Tokens',
  };

  const topItemLinks = Object.keys(topItems).reduce((acc, item) => {
    const link = props.links[item];
    if (!link) return acc;

    return [...acc, { text: topItems[item], href: link }];
  }, []);

  const switchboardVersion = { text: props.switchboardVersion, extraAttrs: { disabled: true } };
  const toggleNotificationPreferences = {
    text: `Toggle email notifications ${user.value.notification_preference ? 'off' : 'on'}`,
    action: toggleUserNotificationPreferences,
  };

  const logoutLink = { text: 'Logout', href: props.links.logout };

  return [
    {
      items: [...topItemLinks, toggleNotificationPreferences, switchboardVersion],
    },
    { items: [logoutLink] },
  ];
});

const tenantLinks = computed(() => {
  const {
    configuration,
    jobs,
    users,
    audit_logs,
    json_override,
    hosted_runners,
    configuration_change_log,
  } = props.links;
  const overviewLink =
    isGitlabUser.value && props.tenant?.id ? `/tenants/${props.tenant.id}/overview` : undefined;

  const links = [
    { href: overviewLink, title: 'Overview' },
    { href: configuration, title: 'Configuration' },
    { href: configuration_change_log, title: 'Configuration change log' },
    { href: jobs, title: 'Jobs' },
    { href: users, title: 'Users' },
    { href: audit_logs, title: 'Audit logs' },
    { href: json_override, title: 'JSON override' },
    { href: hosted_runners, title: 'Hosted runners' },
  ];

  return links.filter((link) => link.href);
});

const { data: pcl } = usePCLQuery();

const { data: tenants } = useTenantsQuery();

const tenantNavigationListener = (e) => {
  if (!isKeyboardNavigationCapable(window.location.pathname)) return;
  if (!isGitlabUser.value) return;
  const isFormElement =
    ['INPUT', 'TEXTAREA'].includes(e.target.tagName) || e.target.isContentEditable;
  if (isFormElement) return;

  if (e.key === 'p') {
    const tenantIndex = tenants.value.findIndex((t) => t.id === props.tenant.id);
    const previousTenantIndex = tenantIndex === 0 ? tenants.value.length - 1 : tenantIndex - 1;
    const previousTenantId = tenants.value[previousTenantIndex].id;

    window.location.href = replaceTenantIdInURL(previousTenantId, window.location.pathname);
  }

  if (e.key === 'n') {
    const tenantIndex = tenants.value.findIndex((t) => t.id === props.tenant.id);
    const nextTenantIndex = tenantIndex === tenants.value.length - 1 ? 0 : tenantIndex + 1;
    const nextTenantId = tenants.value[nextTenantIndex].id;

    window.location.href = replaceTenantIdInURL(nextTenantId, window.location.pathname);
  }
};

window.addEventListener('keydown', tenantNavigationListener);

onBeforeUnmount(() => {
  window.removeEventListener('keydown', tenantNavigationListener);
});

const tenantSearchOpen = ref(false);
const openTenantSearch = () => {
  tenantSearchOpen.value = true;
};

const flashNotice = computed(() => {
  // TODO: These will have to be moved to route instead
  const url = new URL(window.location.href);
  if (url.searchParams.get('override_success')) {
    url.searchParams.delete('override_success');
    window.history.replaceState(null, '', url.toString());

    return {
      text: 'JSON override successfully updated',
      variant: 'success',
    };
  }

  return null;
});
</script>

<template>
  <quick-tenant-search v-if="isGitlabUser" v-model:visible="tenantSearchOpen" />

  <gl-broadcast-message
    v-if="pcl"
    icon-name="bullhorn"
    theme="red"
    type="banner"
    data-testid="pcl-alert"
    :dismissible="false"
  >
    <p>
      {{ pcl.description }}
    </p>
  </gl-broadcast-message>

  <nav
    v-bind="$attrs"
    class="navbar navbar-light navbar-expand-lg gl-bg-gray-50 gl-shadow-sm gl-shadow-gray-100"
  >
    <div class="container">
      <div class="gl-flex gl-items-center gl-gap-2">
        <a :href="links.home_path" class="navbar-brand gl-flex gl-items-center">
          <div class="nav-logo gl-pr-4">
            <tanuki-logo />
          </div>

          <span data-testid="appTitle">Switchboard {{ envLabel }}</span>
        </a>

        <gl-button
          v-if="isGitlabUser && shouldShowSearchButton"
          data-testid="openTenantSearchButton"
          variant="default"
          category="primary"
          button-text-classes="gl-gap-2 gl-flex gl-items-center"
          @click="openTenantSearch"
        >
          <gl-icon name="search" />

          <span>Search</span>

          <span><kbd>⌘</kbd> <kbd>K</kbd></span>
        </gl-button>
      </div>

      <ul class="nav navbar-nav">
        <li v-if="tenant" class="navbar-text mr-2">{{ tenant.name }}</li>
        <gl-disclosure-dropdown v-if="user" :toggle-text="user.email" :items="userMenuItems" />
      </ul>
    </div>
  </nav>

  <div
    v-if="tenant && tenantLinks.length"
    class="navbar navbar-light navbar-expand-lg gl-border-b gl-bg-gray-10"
    data-testid="tenant-links"
  >
    <div class="container">
      <ul class="nav navbar-nav">
        <li v-for="link in tenantLinks" :key="link.href">
          <gl-link
            data-testid="tenant-link"
            :href="link.href"
            class="nav-link gl-rounded-base !gl-text-gray-700 hover:gl-bg-gray-100 active:gl-text-white"
          >
            {{ link.title }}
          </gl-link>
        </li>
      </ul>
    </div>
  </div>

  <div class="container">
    <api-error-alert v-if="apiError" :error="apiError" @dismiss="apiError = null" />

    <gl-alert
      v-if="notificationPreferencesUpdated"
      class="gl-my-4"
      title=""
      dismissible
      show-icon
      dismiss-label="Dismiss"
      variant="info"
      :sticky="false"
      @dismiss="notificationPreferencesUpdated = false"
    >
      Your notification preferences have been updated
    </gl-alert>

    <gl-alert
      v-if="flashNotice"
      data-testid="flash-notice"
      :variant="flashNotice.variant"
      :dismissible="false"
      class="gl-my-4"
    >
      {{ flashNotice.text }}
    </gl-alert>
  </div>
</template>
