import { parseQueryParams } from '~/helpers/url_utilities';
import axios from '~/api/axios';

export const getTenant = (tenantId) => axios.get(`/tenants/${tenantId}.json`);

/**
 * Get a tenant with serialized data ready for the `/edit` route (JSON override)
 * See: https://gitlab.com/gitlab-com/gl-infra/gitlab-dedicated/switchboard/-/merge_requests/2096#note_2233366971
 * @param {String|Number} tenantId
 * @returns
 */
export const getTenantEdit = (tenantId) => axios.get(`/tenants/${tenantId}/edit.json`);

/**
 * Get all the available tenants
 * @returns {Promise}
 */
export const getAllTenants = () => axios.get('/tenants.json');

export const createTenant = (body) => axios.post('/tenants', body);

export const updateTenant = (tenantId, body) => axios.patch(`/tenants/${tenantId}`, body);

export const updateOverride = (tenantId, body) =>
  axios.patch(`/tenants/${tenantId}/json_override`, body);

export const partialUpdate = (tenantId, entity, body) =>
  axios.put(`/tenants/${tenantId}/${entity}`, body);

export const fetchCredentials = (tenantId) => axios.get(`/tenants/${tenantId}/root_credentials`);

/**
 * Fetch a list of supported aws regions
 * @returns {Promise}
 */
export const getAWSRegions = () => axios.get('/tenants/aws_supported_regions');

/**
 * Fetch a list of all config changes for a tenant in all different states.
 *
 * pending - change is saved, but not applied
 * queued - change is saved and in the process of being applied(user clicks apply now or maintenance picks it up)
 * deployed - change has been applied
 * replaced - change has been replaced by another
 * @param {Number|String} tenantId
 * @returns {Promise}
 */
export const getConfigChanges = (tenantId) => axios.get(`/tenants/${tenantId}/config_changes`);

/**
 * Fetch a list of config change logs for a tenant
 *
 * @param {Number|String} tenantId
 * @returns {Promise}
 */
export const getConfigChangeLogs = (tenantId, { page }) =>
  axios.get(`/tenants/${tenantId}/config_change_logs?${parseQueryParams({ page })}`);

/**
 * Add or update BYOD configuration for a tenant
 * @param {String|Number} tenantId
 * @param {Object} payload
 * @param {String} payload.instance_domain
 * @param {String} [payload.kas_domain]
 * @param {String} [payload.registry_domain]
 * @returns
 */
export const updateBYOD = (tenantId, payload) => {
  if (!payload.instance_domain) {
    throw new Error('instance_domain can not be unset');
  }

  return axios.patch(`/tenants/${tenantId}/byod`, payload);
};

/**
 * Delete BYOD configuration from a tenant
 * @param {String|Number} tenantId
 */
export const deleteBYOD = (tenantId) => axios.delete(`/tenants/${tenantId}/byod`);

/**
 * Fetch a single config log for a tenant
 *
 * @param {Number|String} tenantId
 * @param {Number|String} logId
 * @returns
 */
export const getConfigChangeLog = (tenantId, logId) =>
  axios.get(`/tenants/${tenantId}/config_change_logs/${logId}`);

export const updateSMTPEmailConfig = (tenantId, payload) =>
  axios.patch(`/tenants/${tenantId}/external_smtp_parameters`, payload);

export const deleteSMTPEmailConfig = (tenantId) =>
  axios.delete(`/tenants/${tenantId}/external_smtp_parameters`);

/**
 * Get an object of permissions the current user has within this tenant.
 *
 * @param {Number|String} tenantId
 * @returns
 */
export const getUserPermissions = (tenantId) => axios.get(`/tenants/${tenantId}/user_permissions`);

/**
 * Get one each of the tenant's upcoming, last, and last emergency maintenance windows.
 *
 * @param {Number|String} tenantId
 * @returns
 */
export const getMaintenanceTimeline = (tenantId) =>
  axios.get(`/tenants/${tenantId}/maintenance_timelines.json`);

/**
 * Create a hostname for a tenant's private hosted zone.
 *
 * @param {Object} param0
 * @param {Number|String} param0.tenantId
 * @param {Object} param0.payload
 * @param {String} param0.payload.hostname
 * @param {Array<String>} [param0.payload.aliases]
 * @param {Number} [param0.payload.reverse_private_link_endpoint_service_config_id]
 * @returns
 */
export const createPhzHostname = ({ tenantId, payload }) =>
  axios.post(`/tenants/${tenantId}/private_hosted_zone_hostnames`, payload);

/**
 * Get a list of hostnames for a tenant's private hosted zone.
 *
 * @param {Number|String} tenantId
 * @returns {Promise}
 */
export const getPhzHostnames = (tenantId) =>
  axios.get(`/tenants/${tenantId}/private_hosted_zone_hostnames`);

/**
 * Update a tenant's private hosted zone hostname.
 *
 * @param {Object} param0
 * @param {Number|String} param0.tenantId
 * @param {Number|String} param0.hostnameId
 * @returns {Promise}
 */
export const updatePhzHostname = ({ tenantId, hostnameId, payload }) =>
  axios.put(`/tenants/${tenantId}/private_hosted_zone_hostnames/${hostnameId}`, payload);

/**
 * Deletes a tenant's private hosted zone hostname.
 *
 * @param {Object} param0
 * @param {Number|String} param0.tenantId
 * @param {Number|String} param0.hostnameId
 * @returns {Promise}
 */
export const deletePhzHostname = ({ tenantId, hostnameId }) =>
  axios.delete(`/tenants/${tenantId}/private_hosted_zone_hostnames/${hostnameId}`);
