<script setup>
import { ref, computed, onBeforeUnmount, watch } from 'vue';
import { GlModal, GlSearchBoxByType } from '@gitlab/ui';
import { useTenantsQuery } from '~/queries/use_tenants_query';
import TenantLink from '~/common/tenant_link.vue';
import { isKeyboardNavigationCapable, replaceTenantIdInURL } from '~/helpers/url_utilities';

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:visible']);

const { data: tenants } = useTenantsQuery();

const searchTenantModalVisible = ref(false);
const setModalVisible = (isVisible) => {
  emit('update:visible', isVisible);
  searchTenantModalVisible.value = true;
};

// Sync the prop and the internal value
watch(
  computed(() => props.visible),
  (isVisible) => {
    searchTenantModalVisible.value = isVisible;
  },
  { immediate: true },
);

const searchTenantFilter = ref('');
const filteredTenants = computed(() => {
  if (!searchTenantFilter.value.length) return tenants.value;
  const search = searchTenantFilter.value.trim().toLowerCase();

  return tenants.value.filter((t) =>
    [t.name, t.internal_reference].some((term) => term?.toLowerCase().includes(search)),
  );
});

const searchTenantListener = (event) => {
  if (searchTenantModalVisible.value) {
    if (event.key !== 'Escape') {
      event.stopPropagation();
    }
  }

  // Check if the pressed key is 'K' (key code 75)
  if (event.keyCode === 75) {
    // Check if Command key (Mac) or Control key (Windows) is pressed
    if (event.metaKey || event.ctrlKey) {
      event.preventDefault();

      setModalVisible(true);
    }
  }
};

const hrefForTenant = (id) => {
  // In the tenant index and non navigation pages, default to overview
  if (
    window.location.pathname === '/tenants' ||
    !isKeyboardNavigationCapable(window.location.pathname)
  ) {
    return `/tenants/${id}/overview`;
  }

  return replaceTenantIdInURL(id, window.location.pathname);
};

/**
 * We have to set the `true` on the third param here to make sure this
 * event is added on the `capture` phase, so that we can `stopPropagation`
 * on other keydown listeners such as the `p` and `n` added by main navbar
 * (which are added on the bubbling phase).
 * Otherwise when the user types these letters into the search box they will
 * navigate away from the page.
 *
 * This would be otherwise solved by stopping the event in the input normally
 * however gitlab UI only emits the `string` for the event, and not the event
 * itself which makes it impossible for us to stop it
 */
window.addEventListener('keydown', searchTenantListener, true);
onBeforeUnmount(() => {
  window.removeEventListener('keydown', searchTenantListener);
});
</script>

<template>
  <gl-modal
    :visible="searchTenantModalVisible"
    modal-id="search-tenant-modal"
    title="Tenant quick search"
    data-testid="search-tenant-modal"
    hide-footer
    @change="setModalVisible"
  >
    <gl-search-box-by-type
      v-model="searchTenantFilter"
      data-testid="search-input"
      placeholder="Search"
    />

    <ul class="gl-mt-4">
      <li v-for="filteredTenant in filteredTenants" :key="filteredTenant.id">
        <tenant-link data-testid="tenant-link" :href="hrefForTenant(filteredTenant.id)"
          >{{ filteredTenant.name }} ({{ filteredTenant.internal_reference }})</tenant-link
        >
      </li>
    </ul>
  </gl-modal>
</template>
